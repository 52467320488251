import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class LogsService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }


    getAllSMSOTPLogsWithParams(params: any) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/sms_otp_logs', {
            params: httpParams,
            observe: 'body'
        });
    }

    downloadSMSOTPLogsCSV() {
        return this.httpClient.get(this.config.apiUrl + 
            '/sms_otp_logs/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

}
