import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

export interface TimeFilterInterface {
  startTime: number;
  endTime: number;
}

@Component({
  selector: 'app-poilegend',
  templateUrl: './poilegend.component.html',
  styleUrls: ['./poilegend.component.css']
})
export class POILegendComponent implements OnInit {
  geolocationTypes: any;
  geolocationColors: any;

  constructor() {
    this.geolocationTypes = [
      {
        'imgSrc': 'assets/img/poi_base32/mappanicicon32.png', 
        'typeName': 'Panic Alert', 
        'typeDescription': 'Geolocation'
      },
      {
        'imgSrc': 'assets/img/poi_base32/manual32.png', 
        'typeName': 'Manual Check-in', 
        'typeDescription': 'Geolocation'
      },
      {
        'imgSrc': 'assets/img/poi_base32/mapcheckinicon32.png', 
        'typeName': 'Travel Arrival Check-in', 
        'typeDescription': 'Geolocation'
      },
      {
        'imgSrc': 'assets/img/poi_base32/mapbackgroundlocationicon32.png', 
        'typeName': 'Back-ground Location', 
        'typeDescription': 'Geolocation'
      },
      {
        'imgSrc': 'assets/img/poi_base32/mapiamfineicon32.png', 
        'typeName': 'I’m fine', 
        'typeDescription': 'Geolocation'
      },
      {
        'imgSrc': 'assets/img/poi_base32/mapsosicon32.png', 
        'typeName': 'SOS', 
        'typeDescription': 'Geolocation'
      }
      
    ];

    this.geolocationColors = [
      {
        'hex': '#007700', 
        'description': 'Geolocation is less than 6 hours old', 
      },
      {
        'hex': '#ff7351', 
        'description': 'Geolocation is between 6 and 12 hours old', 
      },
      {
        'hex': '#ff351f', 
        'description': 'Geolocation is between 12 to 48 hours old', 
      },
      {
        'hex': '#6A6A6A', 
        'description': 'Geolocation is more than 48 hours old', 
      },
    ];
  }

  ngOnInit() {
    
  }

}
