import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var LogsService = /** @class */ (function () {
    function LogsService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    LogsService.prototype.getAllSMSOTPLogsWithParams = function (params) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/sms_otp_logs', {
            params: httpParams,
            observe: 'body'
        });
    };
    LogsService.prototype.downloadSMSOTPLogsCSV = function () {
        return this.httpClient.get(this.config.apiUrl +
            '/sms_otp_logs/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    LogsService.ngInjectableDef = i0.defineInjectable({ factory: function LogsService_Factory() { return new LogsService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: LogsService, providedIn: "root" });
    return LogsService;
}());
export { LogsService };
