import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var PanicService = /** @class */ (function () {
    function PanicService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    PanicService.prototype.getLocationPanicInfo = function (locationId) {
        return this.httpClient.get(this.config.apiUrl
            + '/location/'
            + locationId
            + '/panic/info');
    };
    PanicService.prototype.getAllDevicePanicsLogsWithParams = function (params) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/panics_logs_from_devices', {
            params: httpParams,
            observe: 'body'
        });
    };
    PanicService.prototype.downloadPanicsLogsFromDevicesCSV = function () {
        return this.httpClient.get(this.config.apiUrl +
            '/panics_logs_from_devices/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    PanicService.ngInjectableDef = i0.defineInjectable({ factory: function PanicService_Factory() { return new PanicService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: PanicService, providedIn: "root" });
    return PanicService;
}());
export { PanicService };
