

<div class="col-md-10 col-md-offset-1">
  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>
      
      <p-table #dt [value]="asyncLogSMSOTPErrors | async" [paginator]="true" [rowsPerPageOptions]="[25, 50, 100, 250, 500]"
        paginatorPosition="both" [rows]="25" autoLayout="true" [lazy]="true" [totalRecords]="count" 
        (onLazyLoad)="loadTransactionsLazy($event)"
        [(selection)]="selectedSSOLogs" dataKey="id"
        [responsive]="true">

        <ng-template pTemplate="caption">
          SMS OTP Logs
          <button mat-icon-button 
            (click)="downloadPanicsLogsFromDevices()">
            <fa-icon [icon]="faFileCsv" style="font-size: 40px;"></fa-icon>
          </button>
        </ng-template>
        
        <ng-template pTemplate="header">
          <tr>
            <th [pSortableColumn]="'time'">
              {{ 'Date created (GMT))'}}
              <p-sortIcon [field]="'time'"></p-sortIcon>
            </th>
            <th>{{ 'Mobile user email'}}</th>
            <th>{{ 'Group'}}</th>
            <th>{{ 'Pole'}}</th>
            <th>{{ 'Error'}}</th>
            <th>{{ 'To number'}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData">
            <td>
              <span class="ui-column-title">
                {{ 'Date created (GMT)' }}
              </span>
              {{ rowData.date_created }}
            </td>
            <td>
              <span class="ui-column-title">
                {{ 'Mobile user email' }}
              </span>
              {{ rowData.email }}
            </td>
            <td>
              <span class="ui-column-title">
                {{ 'Group' }}
              </span>
              {{ rowData.mobileuser?.group?.name_en }}
            </td>
            <td>
              <span class="ui-column-title">
                {{ 'Pole' }}
              </span>
              {{ rowData.mobileuser?.pole?.name_en }}
            </td>
            <td>
              <span class="ui-column-title">
                {{ 'Twillo error' }}
              </span>
              {{ rowData.twillo_error }}
            </td>
            <td>
              <span class="ui-column-title">
                {{ 'To number' }}
              </span>
              {{ rowData.to_number }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

