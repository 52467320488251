import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class PanicService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getLocationPanicInfo(locationId) {
        return this.httpClient.get<any>(
            this.config.apiUrl 
            + '/location/' 
            + locationId 
            + '/panic/info'
        );
    }

    getAllDevicePanicsLogsWithParams(params: any) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/panics_logs_from_devices', {
            params: httpParams,
            observe: 'body'
        });
    }

    downloadPanicsLogsFromDevicesCSV() {
        return this.httpClient.get(this.config.apiUrl + 
            '/panics_logs_from_devices/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

}
