import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var MobileUserService = /** @class */ (function () {
    function MobileUserService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    MobileUserService.prototype.getAll = function () {
        return this.httpClient.get(this.config.apiUrl + '/mobileusers/all');
    };
    MobileUserService.prototype.getAllWithRegistrationOnFirebase = function () {
        return this.httpClient.get(this.config.apiUrl + '/mobileusers/all_with_firabase_registration');
    };
    MobileUserService.prototype.filterAccessibleUsersWithFirebase = function (filterValue) {
        return this.httpClient.post(this.config.apiUrl + '/mobileusers/filter_all_with_firabase_registration', { 'filter_value': filterValue });
    };
    MobileUserService.prototype.getAllWithParams = function (params) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/mobileusers', {
            params: httpParams,
            observe: 'body'
        });
    };
    MobileUserService.prototype.getCountryAlertedWithParams = function (params, country_id) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/mobileusers/alerted_for_country/' + country_id, {
            params: httpParams,
            observe: 'body'
        });
    };
    MobileUserService.prototype.getById = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/mobileusers/' + _id);
    };
    MobileUserService.prototype.getByUsersIds = function (_ids) {
        return this.httpClient.post(this.config.apiUrl + '/mobileusers/get_all_with_ids', { 'pob_ids': _ids });
    };
    MobileUserService.prototype.getAlertedCountries = function (userId) {
        return this.httpClient.get(this.config.apiUrl + '/mobileusers/' + userId + '/alerted_countries');
    };
    MobileUserService.prototype.getUserResponders = function (userId) {
        return this.httpClient.get(this.config.apiUrl + '/mobileusers/' + userId + '/my_requests');
    };
    MobileUserService.prototype.create = function (user) {
        return this.httpClient.post(this.config.apiUrl + '/mobileusers', user);
    };
    MobileUserService.prototype.update = function (user) {
        return this.httpClient.put(this.config.apiUrl + '/mobileusers/' + user.id, user);
    };
    MobileUserService.prototype.delete = function (_id) {
        return this.httpClient.delete(this.config.apiUrl + '/mobileusers/' + _id);
    };
    MobileUserService.prototype.blockUser = function (id) {
        return this.httpClient.get(this.config.apiUrl + '/mobileusers/' + id + '/block');
    };
    MobileUserService.prototype.unblockUser = function (id) {
        return this.httpClient.get(this.config.apiUrl + '/mobileusers/' + id + '/unblock');
    };
    MobileUserService.prototype.confirmUserEmail = function (id) {
        return this.httpClient.get(this.config.apiUrl + '/mobileusers/' + id + '/confirm_email');
    };
    MobileUserService.prototype.confirmUserSponsor = function (id) {
        return this.httpClient.get(this.config.apiUrl + '/mobileusers/' + id + '/confirm_sponsor');
    };
    MobileUserService.prototype.deleteBulkOfUsers = function (users) {
        var ids = [];
        if (users && users.length > 0) {
            ids = users.map(function (a) { return a.id; });
        }
        return this.httpClient.post(this.config.apiUrl + '/mobileusers/bulkdelete', { "ids": ids });
    };
    MobileUserService.prototype.downloadUsersCSV = function (showCorporateUsersTable, showIncompleteRegInfo) {
        return this.httpClient.get(this.config.apiUrl +
            '/mobileusers/corporate/' + ((showCorporateUsersTable) ? 1 : 0) +
            '/incomplete_registrations/' + ((showIncompleteRegInfo) ? 1 : 0) +
            '/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    MobileUserService.prototype.downloadCountrySubscribedUsersCSV = function (countryId) {
        return this.httpClient.get(this.config.apiUrl
            + '/mobileusers/countries/'
            + countryId + '/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    MobileUserService.prototype.downloadCountryAlertedUsersCSV = function (countryId) {
        return this.httpClient.get(this.config.apiUrl
            + '/mobileusers/alerted_for_country/'
            + countryId + '/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    MobileUserService.prototype.exportCountryAlertedUsersPdf = function (countryId) {
        return this.httpClient.get(this.config.apiUrl
            + '/mobileusers/alerted_for_country/'
            + countryId + '/pdf', {
            headers: new HttpHeaders().set('Accept', 'application/pdf'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    MobileUserService.prototype.downloadUserInfo = function (userId) {
        return this.httpClient.get(this.config.apiUrl +
            '/mobileusers/' + userId + '/user_info/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    MobileUserService.prototype.changeStatus = function (statusLetter, userId) {
        return this.httpClient.put(this.config.apiUrl + '/mobileusers/' + userId + '/status', { 'status_letter': statusLetter });
    };
    MobileUserService.prototype.linkUsersByEmails = function (userId, ssoLogId, ssoLogEmail) {
        return this.httpClient.post(this.config.apiUrl + '/mobileusers/'
            + userId + '/sso_log/' + ssoLogId + '/link_sso_email_to_user_email', {
            'sso_email': ssoLogEmail
        });
    };
    MobileUserService.ngInjectableDef = i0.defineInjectable({ factory: function MobileUserService_Factory() { return new MobileUserService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: MobileUserService, providedIn: "root" });
    return MobileUserService;
}());
export { MobileUserService };
